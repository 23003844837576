import React, { useState, useEffect, useReducer } from 'react'

import moment from 'moment'
import EventTopBar from '../event-template/EventTopBar'
import EventFooter from '../event-template/EventFooter'
import { Link, useHistory } from 'react-router-dom'
import { Select, Collapse, Button, Input, InputNumber, message } from 'antd'
import { isValidEmail } from '../../../utils'
import { EyeIcon, Loader, LoaderMessage, Delete } from '../../../config/Svgicon'
import { usersService } from '../../../_services/usersService'
import ResponseFilter from '../../../config/response-handler'
import InputMask from 'react-input-mask'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'
import ReactPhoneInput from 'react-phone-input-2'
import phone from 'phone'
import _ from 'lodash'
import aes256 from 'aes256'

// local imports, mostly images
import amexIcon from '../../../assets/images/amex.png'
import mastercardIcon from '../../../assets/images/mastercard-logo.png'
import discoverIcon from '../../../assets/images/discover.png'
import visaIcon from '../../../assets/images/visa.png'
const { Option } = Select
const { Panel } = Collapse
const dateFormat = 'MM-DD-YYYY'

// all state update logic is consolidated in this function.
function reducer (state, action) {
  console.log(state)
  console.log(action)
  switch (action.type) {
    //fire on any async request initialization
    case 'REQ_INIT':
      return { ...state, isLoading: true, isError: false }
    //fire on any async request success
    case 'REQ_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        errorMessage: ''
      }
    //fire on any async request failure
    case 'REQ_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload
      }
    // handles all the updates for the form fields that we'll eventually send to the checkout endpoint
    case 'UPDATE_FORM':
      let newFormInfo = _.cloneDeep(state.formInfo)
      newFormInfo = _.set(
        newFormInfo,
        action.payload.path,
        action.payload.value
      )
      return {
        ...state,
        formInfo: newFormInfo
      }
    // handles the switch for the separate ticket delivery option (on/off)
    case 'TOGGLE_TICKET_DELIVERY':
      return {
        ...state,
        separateTicketDelivery: action.payload
      }
    // updates the state for the ticket recipients
    case 'UPDATE_TICKET_DELIVERY':
      let updatedState = state.formInfo.separateTicketRecipients.map(
        (item, index1) => {
          if (index1 === action.payload.index) {
            item[action.payload.path] = action.payload.value
          }
          return item
        }
      )
      return {
        ...state,
        formInfo: { ...state.formInfo, separateTicketRecipients: updatedState }
      }
    case 'DELETE_TICKET_RECIPIENT_ROW':
      let updatedState1 = state.formInfo.separateTicketRecipients.filter(
        (item, index1) => {
          return index1 !== action.payload
        }
      )
      return {
        ...state,
        formInfo: { ...state.formInfo, separateTicketRecipients: updatedState1 }
      }
    // updates the coupon object
    case 'ADD_COUPON':
      return {
        ...state,
        formInfo: {
          ...state.formInfo,
          coupon: action.payload.value,
          fees: Number(localStorage.getItem('PerTicketFee') || 0) +
          Number(localStorage.getItem('CCFees') || 0) +
          Number(localStorage.getItem('TransactionFee') || 0),
          grandTotal:
            state.formInfo.grandTotal -
            Number(
              action.payload.value.discountType === 1
                ? action.payload.value.discount * 0.01 * state.ticketDetails.filter(e => action.payload.value.selectedTickets.includes(e.ticketId)).reduce((acc, curr) => Number(curr.ticketPrice) > 0 ? acc + curr.ticketPrice : acc, 0)
                : action.payload.value.discount * state.ticketDetails.filter(e => action.payload.value.selectedTickets.includes(e.ticketId)).reduce((acc, curr) => Number(curr.ticketPrice) > 0 ? acc + 1 : acc, 0)
            ).toFixed(2),
          discountAmount: Number(
            action.payload.value.discountType === 1
            ? action.payload.value.discount * 0.01 * state.ticketDetails.filter(e => action.payload.value.selectedTickets.includes(e.ticketId)).reduce((acc, curr) => Number(curr.ticketPrice) > 0 ? acc + curr.ticketPrice : acc, 0)
            : action.payload.value.discount * state.ticketDetails.filter(e => action.payload.value.selectedTickets.includes(e.ticketId)).reduce((acc, curr) => Number(curr.ticketPrice) > 0 ? acc + 1 : acc, 0)
          ).toFixed(2)
        }
      }
    case 'REMOVE_COUPON':
      return {
        ...state,
        formInfo: {
          ...state.formInfo,
          fees: Number(localStorage.getItem('PerTicketFee') || 0) +
          Number(localStorage.getItem('CCFees') || 0) +
          Number(localStorage.getItem('TransactionFee') || 0),
          coupon: { name: '', discount: 0, discountType: '', couponId: '' },
          grandTotal: state.formInfo.subtotal + state.formInfo.fees,
          discountAmount: 0
        }
      }
    case 'ADD_100_OFF_COUPON':
      return {
        ...state,
        formInfo: {
          ...state.formInfo,
          coupon: action.payload.value,
          fees: 0,
          discountAmount: Number(state.formInfo.grandTotal),
          grandTotal: 0,
        }
      }
    // updates the agree to terms checkbox
    case 'TOGGLE_AGREE_TO_TERMS':
      return {
        ...state,
        agreeToTerms: action.payload
      }
    case 'CHECKOUT_ERROR':
      return {
        ...state,
        checkoutError: true,
        errorMessage: action.payload ?? ''
      }
    // if none of the above matches, throw an error
    default:
      throw new Error()
  }
}

// main checkout component
const EventCheckout = props => {
  // for redirection
  const history = useHistory()

  // when component mounts, set the  initial state of the reducer
  const [state, dispatch] = useReducer(reducer, {
    isLoading: false,
    isError: false,
    checkoutError: false,
    loaderMessage: '',
    errorMessage: '',
    firstname: '',
    formInfo: {
      // all of the fields that get sent to the backend are in this object, except for ticketDetails
      contactInformation: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        dob: '',
        gender: ''
      },
      confirmEmail: '',
      billingInformation: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: ''
      },
      cardDetails: {
        cardNumber: '',
        expireDate: '',
        cvv: '',
        zipCode: ''
      },
      vaccinationCertificate: '',
      isReportRequired: false,
      companyName: '',
      titleAtCompany: '',
      coupon: {
        name: '',
        discount: 0,
        discountType: '', // 1==%, 2==$amount
        couponId: '',
      },
      subtotal: Number(localStorage.getItem('subtotal')) || 0,
      fees:
        Number(localStorage.getItem('PerTicketFee') || 0) +
        Number(localStorage.getItem('CCFees') || 0) +
        Number(localStorage.getItem('TransactionFee') || 0),
      grandTotal: 0,
      discountAmount: 0,
      eventId: localStorage.getItem('eventID') || '',
      userId: localStorage.getItem('userId') || '',
      password: '',
      confirmPassword: '',
      separateTicketRecipients: [],
      checkoutFields: localStorage.getItem('checkoutFields')
        ? JSON.parse(localStorage.getItem('checkoutFields'))
        : {},
      checkoutCustomFields: localStorage.getItem('checkoutCustomFields')
        ? JSON.parse(localStorage.getItem('checkoutCustomFields')).filter(
            e => e.checked !== 0
          )
        : []
    },
    cipher: aes256.createCipher(process.env.REACT_APP_AES256_KEY),
    agreeToTerms: false,
    separateTicketDelivery: null,
    // separateTicketDeliveryMode:
    // props.location.state?.separateTicketDeliveryMode, // separate ticket delivery currently hardcoded as "allowed", but this is where we would put the value from the event details page (on/off/allowed)
    separateTicketDeliveryMode: 'allowed',
    ticketDetails: JSON.parse(
      localStorage.getItem('orderTicketList') // this is the list of tickets that user has selected
    ).flatMap(
      // flatten the array and give each ticket its own entry, instead of grouping by ticket type
      item => {
        // if the ticket type only has one ticket, just return the item, otherwise, create an array from the ticket type
        if (item.ticketQuantity > 1) {
          let ticketArr = []
          for (let j = 0; j < item.ticketQuantity; j++) {
            ticketArr.push({
              ...item,
              ticketQuantity: 1
            })
            // console.log(ticketArr)
          }
          return ticketArr
        } else {
          return item
        }
      }
    ),
    stackedTicketDetails: JSON.parse(
      localStorage.getItem('orderTicketList') // same as above, but not flattened. used for the separate ticket delivery option
    )
  })

  const [createAccount, setCreateAccount] = useState(false)
  const getFeeInfoById = () => {
    if (state.formInfo.fees === 0 && state.formInfo.subtotal > 0 && state.formInfo.coupon.couponId === ''){
    let obj = {
      eventId: state.formInfo.eventId,
    }

    usersService.eventDetails(obj).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
      console.log('EVENT INFO',resData);
      let {ccFees,perTicketFees,transactionFees} = resData.result;
      localStorage.setItem(
        "ccFeesValue",
        ccFees !== "" && ccFees !== null && ccFees !== NaN && ccFees !== undefined ? ccFees : 2.99
      )
      localStorage.setItem(
        "perTicketFeesValue",
        perTicketFees !== "" && perTicketFees !== NaN && perTicketFees !== null && perTicketFees !== undefined
          ? perTicketFees
          : 0.99
      )
      localStorage.setItem(
        "transactionFeesValue",
        transactionFees !== "" && transactionFees !== null && transactionFees !== NaN && transactionFees !== undefined
          ? transactionFees
          : 2.75
      )
     
      console.log('fees set');
        dispatch({
          type: 'UPDATE_FORM',
          payload: {
            path: 'fees',
            value:  Number(localStorage.getItem('PerTicketFee') || 0) +
            Number(localStorage.getItem('CCFees') || 0) +
            Number(localStorage.getItem('TransactionFee') || 0),
          }
        })
      }
    })
    } else {
      console.log('Fees in place');
    }
  }
  getFeeInfoById();
  useEffect(() => {
    let grandTotal = (Math.round((state.formInfo.subtotal + Number(localStorage.getItem('PerTicketFee') || 0) +
    Number(localStorage.getItem('CCFees') || 0) +
    Number(localStorage.getItem('TransactionFee') || 0) - state.formInfo.discountAmount) * 100) / 100).toFixed(2);
    dispatch({
      type: 'UPDATE_FORM',
      payload: {
        path: 'grandTotal',
        value: grandTotal
      }
    })
  }, [state.formInfo.subtotal, state.formInfo.fees, state.formInfo.discountAmount])

  const handleCheckout = async () => {
    // validate the form data
    let formValidation = await validateForm()
    console.log('formValidation: ', formValidation)
    if (!formValidation) {
      dispatch({ type: 'CHECKOUT_ERROR' })
      return
    } // if the form validation fails, return out of the function

    // if the form validation passes, check to make sure the fees are correct
    if (state.formInfo.fees === 0 && state.formInfo.subtotal > 0 && state.formInfo.coupon.couponId === '') {
      dispatch({
        type: 'CHECKOUT_ERROR',
        payload: {
          checkoutErrorMessage: 'Fees were not calculated correctly. Please try again.'
        }
      })
      return
    } 
    dispatch({ type: 'REQ_INIT' })

    // check the ticket quantity to make sure it's not more than the available tickets
    let ticketCheckResponse = await usersService.checkTicketQuantity(
      JSON.stringify(state.stackedTicketDetails)
    )
    console.log('ticketCheckResponse: ', ticketCheckResponse)

    // build the formData object
    let orderObject = new FormData()
    for (const [key, value] of Object.entries(state.formInfo)) {
      // if the value is not empty, append it to the formData
      if (value !== '') {
        // if the value is an object, stringify it, and if it's the cardDetails object, encrypt it
        console.log(key, value)

        let stringifiedValue =
          typeof value === 'object' ? JSON.stringify(value) : value
        if (key === 'cardDetails') {
          stringifiedValue = state.cipher.encrypt(
            JSON.stringify({
              cardNumber: value.cardNumber.split(' ').join(''),
              expireDate: value.expireDate.split('/').join(''),
              cvv: value.cvv.trim(),
              zipCode: value.zipCode
            })
          )
          console.log(state.cipher.decrypt(stringifiedValue))
        }
        orderObject.append(key, stringifiedValue)
      }
    }
    console.log('--------')
    // add the tickets object to the formData
    orderObject.append('ticketDetails', JSON.stringify(state.ticketDetails))
    orderObject.append(
      'stackedTicketDetails',
      JSON.stringify(state.stackedTicketDetails)
    )
    if(localStorage.getItem('eventID')=='66e9d2c940a762a1f54a667b'){

      orderObject.append(
        'taxRate',0.09
      )
      let subTotal = orderObject.get('grandTotal');
      let taxes = (Number(subTotal)*0.09).toFixed(2);
      orderObject.append(
        'taxes',taxes
      )
      orderObject.set(
        'grandTotal',(Number(subTotal)+Number(taxes)).toFixed(2)
      )
    }
    console.log('orderObject: ')

    // make the api call
    for (var pair of orderObject.entries()) {
      console.log(pair);
  }

    let checkoutResponse = await usersService.orderCheckout(orderObject)
    // let checkoutResponse = 'temp'
    console.log('checkoutResponse: ', checkoutResponse)

    if (checkoutResponse.data.statusCode === 1) {
      message.success('Proccess Completed Successfully ', 7)
      // ResponseFilter(checkoutResponse); // this can probably be removed. Currently commented out pending testing.

      history.push({
        pathname: `/thank-you/${checkoutResponse.data.responseData.orderId}`,
        state: {
          eventImage: props.location.state.eventImage,
          orderId: checkoutResponse.data.responseData.orderId,
          successPageMessage: props.location.state.successPageMessage // maybe not neccessary?
        }
      })
    } else {
      message.error(checkoutResponse.data.error.errors.message);
      dispatch({
        type: 'REQ_FAILURE',
        payload: {
          message: { couponErrorMessage: 'Something went wrong. Please try again.' }
        }
      })
      return;
    }

    // handle the response and clear all checkout data from localStorage after the call is complete and returns okay
    localStorage.clear()

    dispatch({ type: 'REQ_SUCCESS' })
  }

  const validateForm = async () => {
    // validate the form data
    let inputs = document.querySelectorAll('.checkout-form input')
    console.log(inputs)
    let inputValidationStatus = []
    inputs.forEach((input, index, array) => {
      console.log(input)
      switch (input.name) {
        case 'cardDetails.cardNumber':
        case 'cardDetails.expireDate':
        case 'cardDetails.cvv':
        case 'contactInformation.phone':
        case '':
          if (
            input.value === '' &&
            !input.classList.contains('ant-select-selection-search-input')
          ) {
            console.log('invalid', input)
            input.nextElementSibling.style.display = 'block'
            inputValidationStatus.push(false)
          }
          break
        case 'separateTicketDeliveryToggle':
          if (state.separateTicketDelivery === null) {
            console.log('invalid', input)
            document.querySelector(
              '#separateTicketDeliveryError'
            ).style.display = 'block'
            inputValidationStatus.push(false)
          }
          break
        default:
          if (!input.validity.valid) {
            console.log('invalid: ', input)
            input.nextElementSibling.style.display = 'block'
            inputValidationStatus.push(false)
          }
      }
    })

    // make sure all tickets are assigned
    if (state.separateTicketDelivery) {
      let ticketList = _.cloneDeep(state.stackedTicketDetails)
      let recipientList = _.cloneDeep(state.formInfo.separateTicketRecipients)

      recipientList.forEach(recipient => {
        ticketList[
          ticketList.findIndex(ticket => ticket.ticketId === recipient.ticketId)
        ].ticketQuantity -= recipient.ticketQuantity
      })
      let leftOver = ticketList.reduce(
        (acc, curr) => acc + curr.ticketQuantity,
        0
      )
      // if (
      //   (state.separateTicketDeliveryMode === 'on' && leftOver !== 0) ||
      //   (state.separateTicketDeliveryMode === 'allowed' && leftOver < 0)
      // )
      if (leftOver !== 0) {
        document.querySelector('#recipientListError').style.display = 'block'
        return false
      }
    }

    return inputValidationStatus.includes(false) ? false : true
  }

  // address select handler -- since it involves an async call, which we don't want to put in the reducer function, we need a wrapper for the dispatch action
  const handleAddressSelection = async (
    address,
    placeId,
    suggestion,
    formData
  ) => {
    //grab the lat/lng from the address
    let results = await geocodeByAddress(address)
    let latLng = await getLatLng(results[0])

    let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&key=${process.env.REACT_APP_MAP_KEY}`

    // grab the street/state/city/zip from google maps api
    let response = await fetch(url)
    let result = await response.json()

    let zipcode = ''
    let state = ''
    let city = ''

    // loop through the api response to grab the relevant data
    for (var i = 0; i < result.results.length; i++) {
      for (var j = 0; j < result.results[i].address_components.length; j++) {
        for (
          var k = 0;
          k < result.results[i].address_components[j].types.length;
          k++
        ) {
          if (
            result.results[i].address_components[j].types[k] === 'postal_code'
          ) {
            zipcode = result.results[i].address_components[j].short_name
          }
          if (result.results[i].address_components[j].types[k] === 'locality') {
            city = result.results[i].address_components[j].long_name
          }
          if (
            result.results[i].address_components[j].types[k] ===
            'administrative_area_level_1'
          ) {
            state = result.results[i].address_components[j].long_name
          }
        }
      }
    }

    // dispatch the action to update the form with the new address data
    dispatch({
      type: 'UPDATE_FORM',
      payload: {
        path: 'billingInformation',
        value: {
          address1: address.split(',')[0],
          city: city,
          state: state,
          zip: zipcode
        }
      }
    })
  }

  // coupon handler -- since it involves an async call, which we don't want to put in the reducer function, we need a wrapper for the dispatch action
  const handleCoupon = async () => {
    let obj = {
      eventId: state.formInfo.eventId,
      couponApply: state.formInfo.coupon.name
    }
    dispatch({ type: 'REQ_INIT' })
    if (state.formInfo.coupon.couponId === '') {
      usersService.couponList(obj).then(res => {
        let { status, resData } = ResponseFilter(res)
        if (status) {
          // add loader here ?
          let errMessage = 'Please enter a valid coupon.'
          // update the coupon state only if the coupon name submitted matches the coupon name returned from the api and applies to the selected tickets
          if (
            resData.result.length !== 0 &&
            resData.result[0].quantity > 0 &&
            resData.result[0].couponName === state.formInfo.coupon.name && 
            state.ticketDetails.filter(e => resData.result[0].selectedTickets.includes(e.ticketId)).length > 0 
          ) {
            let { _id, discount, discountType, selectedTickets } = resData.result[0]
            if (discountType === 1 && discount === 100) {
              if (state.ticketDetails.filter(e => resData.result[0].selectedTickets.includes(e.ticketId)).length >= state.ticketDetails.filter(e => e.ticketType === 2).length) {
                dispatch({
                  type: 'ADD_100_OFF_COUPON',
                  payload: {
                    value: {
                      name: state.formInfo.coupon.name,
                      discount: discount,
                      discountType: discountType,
                      couponId: _id,
                      selectedTickets: selectedTickets
                    }
                  }
                })
                dispatch({ type: 'REQ_SUCCESS' })

              } else {
                errMessage = `This 100% coupon only applies to the following tickets: ${state.stackedTicketDetails.filter(e => resData.result[0].selectedTickets.includes(e.ticketId)).map(item => item.ticketName).join(', ')}`
                console.log(errMessage)
                dispatch({
                  type: 'REQ_FAILURE',
                  payload: {
                    couponErrorMessage: errMessage 
                  }
                })
              }
            } else {
              dispatch({
                type: discountType === 1 && discount === 100 ? 'ADD_100_OFF_COUPON' : 'ADD_COUPON',
                payload: {
                  value: {
                    name: state.formInfo.coupon.name,
                    discount: discount,
                    discountType: discountType,
                    couponId: _id,
                    selectedTickets: selectedTickets
                  }
                }
              })
              dispatch({ type: 'REQ_SUCCESS' })
            }                      
            
          } else {
            dispatch({
              type: 'REQ_FAILURE',
              payload: {
                 couponErrorMessage: 'Please enter a valid coupon.' 
              }
            })
          }
        } else {
          // #TODO set loader false and throw error
          dispatch({
            type: 'REQ_FAILURE',
            payload: {
              
                couponErrorMessage: 'Something went wrong. Please try again.'
              
            }
          })
        }
      })
    } else {
      dispatch({
        type: 'REMOVE_COUPON'
      })
      dispatch({ type: 'REQ_SUCCESS' })
    }
  }

  return (
    <div className='base-wrapper event-detail'>
      {/* if loading, show loader */}
      {state.isLoading ? <Loader message={state.loaderMessage} /> : null}{' '}
      <EventTopBar {...props} />
      <div className=''>
        <div className='sidebar-overlay'></div>
        <div className='content content-event content-event-cart '>
          <h3 className='content-title'>Checkout</h3>
          {/* {state.errorMessage ? (
            <div className='error ml-0 mt-2 mb-4'>{state.errorMessage}</div>
          ) : null} */}
          <div className='card mb-5'>
            <div className='card-body p-0'>
              <div className='row no-gutters'>
                <div className='col-lg-8 border-right'>
                  <div className='p-4 w-100'>
                    <h3 className='text-capitalize font-weight-bold mb-4 name-ticket'>
                      Contact Information
                    </h3>
                    <form className='checkout-form home-form'>
                      <div className='row mb-4'>
                        <div className='col-lg-6 mb-3 mb-xl-0 mb-lg-0 mb-md-3 mb-xs-3'>
                          {/* Start of the form. Almost all the form components use the FormInput child component (the component can be found below) */}
                          <FormInput
                            value={state.formInfo.contactInformation.firstName}
                            name='contactInformation.firstName'
                            dispatch={dispatch}
                            label='First Name'
                            required
                            message='Please enter a valid first name'
                            key='firstName'
                          />
                        </div>
                        <div className='col-lg-6'>
                          <FormInput
                            value={state.formInfo.contactInformation.lastName}
                            name='contactInformation.lastName'
                            dispatch={dispatch}
                            label='Last Name'
                            required
                            message='Please enter a valid last name'
                            key='lastName'
                          />
                        </div>
                      </div>
                      <div className='row mb-5'>
                        <div className='col-lg-6 mb-3 mb-xl-0 mb-lg-0 mb-md-3 mb-xs-3'>
                          <FormInput
                            value={state.formInfo.contactInformation.email}
                            name='contactInformation.email'
                            dispatch={dispatch}
                            label='Email'
                            required
                            message='Please enter a valid email'
                            key='email'
                          />
                        </div>
                        <div className='col-lg-6 mb-3 mb-xl-0 mb-lg-0 mb-md-3 mb-xs-3'>
                          <FormInput
                            value={state.formInfo.confirmEmail}
                            name='confirmEmail'
                            dispatch={dispatch}
                            label='Confirm Email'
                            required
                            message='Please enter a valid email'
                            key='confirmEmail'
                          />
                        </div>
                      </div>
                      {Number(state.formInfo.checkoutFields.phone || 0) ? (
                        <div className='col-lg-6 px-0 pb-4'>
                          <FormInput
                            value={state.formInfo.contactInformation.phone}
                            name='contactInformation.phone'
                            dispatch={dispatch}
                            label='Phone'
                            required
                            message='Please enter a valid phone number'
                            key='phone'
                          />
                        </div>
                      ) : null}
                      {state.formInfo.checkoutFields?.isCertificateAvailable ===
                        1 && (
                        <>
                          <div className='row'>
                            <div className='col-lg-12 mb-xl-0 mb-lg-0 mb-md-1 mb-xs-1'>
                              <label
                                className='text-capitalize'
                                for='vaccination'
                              >
                                Vaccination Status
                                <label className='text-danger'>*</label>
                              </label>
                              <br />
                              <FormInput
                                name='isReportRequired'
                                label='I will provide a negative covid test'
                                value={false}
                                dispatch={dispatch}
                              />
                              <br />
                              <FormInput
                                name='isReportRequired'
                                label='I am vaccinated'
                                value={true}
                                dispatch={dispatch}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      {state.formInfo.isReportRequired === 'true' && (
                        <FormInput
                          value={state.formInfo.vaccinationCertificate}
                          name='vaccinationCertificate'
                          dispatch={dispatch}
                          label='Upload Vaccination Card (only JPEG, PNG & PDF formats will be accepted)'
                          required
                          type='file'
                          accept='.pdf, .jpeg, .png'
                        />
                      )}
                      <br />
                      <div className='row'>
                        {state.formInfo.checkoutFields?.gender === 1 && (
                          <div className='col-lg-6 mb-3 mb-xl-0 mb-lg-0 mb-md-3 mb-xs-3 mb-5'>
                            <FormInput
                              name='contactInformation.gender'
                              label='Select Gender'
                              dispatch={dispatch}
                              required
                              message='Please select gender'
                            />
                          </div>
                        )}
                        {state.formInfocheckoutFields?.dob === 1 && (
                          <div className='col-lg-6 mb-5'>
                            <FormInput
                              value={state.formInfo.contactInformation.dob}
                              name='contactInformation.dob'
                              dispatch={dispatch}
                              label='Birthdate'
                              required
                              message='Please enter a valid birthdate'
                              key='dob'
                              mask='99/99/9999'
                            />
                          </div>
                        )}

                        <div className='row'>
                          {state.formInfo.checkoutCustomFields.map(
                            (field, i) => {
                              return (
                                <>
                                  {field.checked === 1 && (
                                    <div className='col-lg-6 mb-5'>
                                      <FormInput
                                        name={`checkoutCustomFields.${i}.value`}
                                        label={field.name}
                                        dispatch={dispatch}
                                        required
                                        message={`Please enter a valid ${field.name}`}
                                        key={i}
                                      />
                                    </div>
                                  )}
                                </>
                              )
                            }
                          )}
                        </div>
                      </div>
                      <h3 className='text-capitalize font-weight-bold mb-4 name-ticket '>
                        Billing Information
                      </h3>
                      <div className='row mb-4'>
                        <div className='col-lg-6 mb-3 mb-xl-0 mb-lg-0 mb-md-3 mb-xs-3 mb-5'>
                          <label className='text-capitalize' for='address1'>
                            Address 1<span className='text-danger'>*</span>
                          </label>
                          {/* The address autocomplete is its own component with its owns separate event handler */}
                          <PlacesAutocomplete
                            value={state.formInfo.billingInformation.address1}
                            name='billingInformation.address1'
                            autocomplete
                            onChange={e =>
                              dispatch({
                                type: 'UPDATE_FORM',
                                payload: {
                                  path: 'billingInformation.address1',
                                  value: e
                                }
                              })
                            }
                            onSelect={(address, placeId, suggestion) =>
                              handleAddressSelection(
                                address,
                                placeId,
                                suggestion,
                                state.formInfo
                              )
                            }
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading
                            }) => (
                              <>
                                <input
                                  {...getInputProps({
                                    className:
                                      'location-search-input form-control'
                                  })}
                                  maxLength='100'
                                />
                                <div className='autocomplete-dropdown-container'>
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                      ? 'suggestion-item--active'
                                      : 'suggestion-item'
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: '#fafafa',
                                          cursor: 'pointer'
                                        }
                                      : {
                                          backgroundColor: '#ffffff',
                                          cursor: 'pointer'
                                        }
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    )
                                  })}
                                </div>
                              </>
                            )}
                          </PlacesAutocomplete>
                        </div>
                        <div className='col-lg-6'>
                          <FormInput
                            value={state.formInfo.billingInformation.address2}
                            name='billingInformation.address2'
                            dispatch={dispatch}
                            label='Address 2'
                            key='address2'
                          />
                        </div>
                      </div>
                      <div className='row mb-4'>
                        <div className='col-lg-6 mb-3 mb-xl-0 mb-lg-0 mb-md-3 mb-xs-3'>
                          <FormInput
                            value={state.formInfo.billingInformation.city}
                            name='billingInformation.city'
                            dispatch={dispatch}
                            label='City'
                            required
                            message='Please enter a valid city'
                            key='city'
                          />
                        </div>
                        <div className='col-lg-3 col-6'>
                          <FormInput
                            value={state.formInfo.billingInformation.state}
                            name='billingInformation.state'
                            dispatch={dispatch}
                            label='State'
                            required
                            message='Please enter a valid state'
                            key='state'
                          />
                        </div>
                        <div className='col-lg-3 col-6'>
                          <FormInput
                            value={state.formInfo.billingInformation.zip}
                            name='billingInformation.zip'
                            dispatch={dispatch}
                            label='Zip'
                            required
                            message='Please enter a valid zip code'
                            key='zip'
                          />
                        </div>
                      </div>

                      {/* payment section */}
                      {state.formInfo.grandTotal > 0 && (
                        <>
                          <h3 className='text-capitalize font-weight-bold mb-4 name-ticket'>
                            Payment Method
                          </h3>
                          <div className='bg-dark p-2 d-flex align-items-center justify-content-between mb-4'>
                            <div className='text-white d-md-flex align-items-center'>
                              <img
                                src={amexIcon}
                                alt='American Express icon'
                                title=''
                              />
                              <img
                                className='ml-1'
                                src={mastercardIcon}
                                alt='Mastercard icon'
                                title=''
                              />
                              <img
                                className='ml-1'
                                src={discoverIcon}
                                alt='discover icon'
                                title=''
                              />
                              <img
                                className='ml-1'
                                src={visaIcon}
                                alt='Visa icon'
                                title=''
                              />

                              <span className='text-capitalize font-size-14 ml-md-3 ml-1'>
                                Pay with your debit or credit card
                              </span>
                            </div>
                          </div>
                          <div className='row mb-4'>
                            <div className='col-12'>
                              <FormInput
                                value={state.formInfo.cardDetails.cardNumber}
                                name='cardDetails.cardNumber'
                                dispatch={dispatch}
                                label='Card Number'
                                required
                                message='Please enter a valid card number'
                                mask='9999 9999 9999 9999'
                                key='cardNumber'
                              />
                            </div>
                          </div>
                          <div className='row mb-2'>
                            <div className='col-lg-6 mb-3 mb-xl-0 mb-lg-0 mb-md-3 mb-xs-3'>
                              <FormInput
                                value={state.formInfo.cardDetails.expireDate}
                                name='cardDetails.expireDate'
                                dispatch={dispatch}
                                label='Expiration Date'
                                required
                                message='Please enter a valid expiration date'
                                mask='99/99'
                                key='expireDate'
                              />
                            </div>
                            <div className='col-lg-6 mb-3 mb-xl-0 mb-lg-0 mb-md-3 mb-xs-3'>
                              <FormInput
                                value={state.formInfo.cardDetails.cvv}
                                name='cardDetails.cvv'
                                dispatch={dispatch}
                                label='Security Code'
                                required
                                message='Please enter a valid security code'
                                mask='9999'
                                key='cvv'
                              />
                            </div>
                          </div>
                        </>
                      )}
                      {/* ticket delivery section */}
                      {state.separateTicketDeliveryMode && ( // always returns true since the value is hardcoded. Leaving in for now in case we want to change it later
                        <>
                          {/* {state.separateTicketDeliveryMode !== 'off' && (
                            <div className='row mb-3 ml-1'>
                              <span className='string-check string-check-soft-primary mt-3'>
                                <input
                                  type='checkbox'
                                  className='form-check-input'
                                  // value={state.ticketDeliveryToggle}
                                  onChange={() => {
                                    dispatch({
                                      type: 'TOGGLE_TICKET_DELIVERY',
                                      payload: !state.separateTicketDelivery
                                    })
                                  }}
                                  checked={state.separateTicketDelivery}
                                  id='ticketDeliveryToggle'
                                />

                                <label
                                  className='string-check-label'
                                  for='ticketDeliveryToggle'
                                >
                                  Send individual tickets to separate email
                                  addresses?
                                </label>
                              </span>
                            </div>
                          )} */}
                          {
                            <>
                              {/* <div className='row mb-3 ml-1'> */}
                              <div className='mt-4 pt-4'>
                                <input
                                  type='radio'
                                  name='separateTicketDeliveryToggle'
                                  id='separateTicketDeliveryOff'
                                  onChange={e => {
                                    dispatch({
                                      type: 'TOGGLE_TICKET_DELIVERY',
                                      payload: false
                                    })
                                  }}
                                  required
                                />
                                <label className='mx-2 mb-4'>
                                  Have All Tickets Sent To Me
                                </label>
                              </div>
                              {/* <div className='row mb-3 ml-1'> */}
                              <div>
                                <input
                                  type='radio'
                                  name='separateTicketDeliveryToggle'
                                  id={'separateTicketDeliveryOn'}
                                  onChange={e => {
                                    dispatch({
                                      type: 'TOGGLE_TICKET_DELIVERY',
                                      payload: true
                                    })
                                  }}
                                  required
                                />
                                <label className='mx-2 mb-4'>
                                  Send Tickets to Guests
                                </label>
                              </div>
                              <div
                                id='separateTicketDeliveryError'
                                className='error ml-0'
                                style={{ display: 'none' }}
                              >
                                Please select an option
                              </div>
                            </>
                          }
                          {state.separateTicketDelivery &&
                            state.formInfo.separateTicketRecipients.length >
                              0 &&
                            state.formInfo.separateTicketRecipients.map(
                              (item, index) => {
                                return (
                                  <div
                                    className='row mb-3 separateTicketDeliveryContainer'
                                    key={index}
                                    style={{ flexWrap: 'nowrap' }}
                                  >
                                    <Input
                                      placeholder='First Name'
                                      className='col-3'
                                      value={item.firstName}
                                      style={{ minWidth: 110 }}
                                      onChange={e => {
                                        dispatch({
                                          type: 'UPDATE_TICKET_DELIVERY',
                                          payload: {
                                            path: 'firstName',
                                            index: index,
                                            value: e.target.value
                                          }
                                        })
                                      }}
                                    />
                                    <Input
                                      placeholder='Last Name'
                                      className='col-3'
                                      value={item.lastName}
                                      style={{ minWidth: 110 }}
                                      onChange={e => {
                                        dispatch({
                                          type: 'UPDATE_TICKET_DELIVERY',
                                          payload: {
                                            path: 'lastName',
                                            index: index,
                                            value: e.target.value
                                          }
                                        })
                                      }}
                                    />
                                    <Input
                                      placeholder='Email'
                                      className='col-3'
                                      style={{ minWidth: 120 }}
                                      value={item.email}
                                      onChange={e => {
                                        dispatch({
                                          type: 'UPDATE_TICKET_DELIVERY',
                                          payload: {
                                            path: 'email',
                                            index: index,
                                            value: e.target.value
                                          }
                                        })
                                      }}
                                    />
                                    <Select
                                      defaultValue={
                                        state.stackedTicketDetails[0].ticketId
                                      }
                                      style={{ minWidth: 100 }}
                                      onChange={value => {
                                        console.log(value)
                                        dispatch({
                                          type: 'UPDATE_TICKET_DELIVERY',
                                          payload: {
                                            path: 'ticketId',
                                            index: index,
                                            value: value
                                          }
                                        })
                                      }}
                                    >
                                      {state.stackedTicketDetails.map(
                                        (item1, index1) => {
                                          return (
                                            <Option value={item1.ticketId}>
                                              {item1.ticketName}
                                            </Option>
                                          )
                                        }
                                      )}
                                    </Select>
                                    <InputNumber
                                      style={{
                                        minWidth: '50px',
                                        flexShrink: '1'
                                      }}
                                      min={0}
                                      max={
                                        state.separateTicketDeliveryMode ===
                                        'allowed'
                                          ? (state.stackedTicketDetails.filter(
                                              (item1, index1) => {
                                                return (
                                                  item1.ticketId ===
                                                  item.ticketId
                                                )
                                              }
                                            )[0].ticketQuantity || 1) -
                                            state.formInfo.separateTicketRecipients.reduce(
                                              (acc, item1, index1) => {
                                                return item1 === item
                                                  ? acc
                                                  : item1.ticketId ===
                                                    item.ticketId
                                                  ? acc + item1.ticketQuantity
                                                  : acc
                                              },
                                              0
                                            )
                                          : 1
                                      }
                                      disabled={
                                        state.separateTicketDeliveryMode ===
                                        'allowed'
                                          ? false
                                          : true
                                      }
                                      defaultValue={
                                        state.separateTicketDeliveryMode ===
                                        'allowed'
                                          ? 0
                                          : 1
                                      }
                                      onChange={value => {
                                        dispatch({
                                          type: 'UPDATE_TICKET_DELIVERY',
                                          payload: {
                                            path: 'ticketQuantity',
                                            index: index,
                                            value: value
                                          }
                                        })
                                      }}
                                    />
                                    <span
                                      title='Remove row'
                                      class='text-danger d-flex ml-2'
                                      onClick={() =>
                                        dispatch({
                                          type: 'DELETE_TICKET_RECIPIENT_ROW',
                                          payload: index
                                        })
                                      }
                                    >
                                      <Delete />
                                    </span>
                                  </div>
                                )
                              }
                            )}
                          {state.separateTicketDelivery &&
                            state.ticketDetails.length !==
                              state.formInfo.separateTicketRecipients.reduce(
                                (acc, curr) => {
                                  return acc + curr.ticketQuantity
                                },
                                0
                              ) && ( //this can be set to "on" if you want to re-enable the on/off/allowed options. Currently, it's hardcoded to "allowed", but all tickets must be assigned
                              <>
                                <div className='mb-3 mt-1'>
                                  You have{' '}
                                  {state.ticketDetails.length -
                                    state.formInfo.separateTicketRecipients.reduce(
                                      (acc, curr) => {
                                        return acc + curr.ticketQuantity
                                      },
                                      0
                                    )}{' '}
                                  more tickets to assign.
                                </div>

                                <div
                                  style={{ display: 'none' }}
                                  className='error ml-0 mb-3'
                                  id='recipientListError'
                                >
                                  You need to adjust your separate ticket
                                  recipient list to match your order quantity
                                  and types.
                                </div>
                              </>
                            )}
                          {state.separateTicketDelivery &&
                            state.formInfo.separateTicketRecipients.reduce(
                              (acc, curr) => {
                                return acc + curr.ticketQuantity
                              },
                              0
                            ) < state.ticketDetails.length && (
                              <div className='row mb-3 ml-1'>
                                <Button
                                  type='primary'
                                  onClick={() => {
                                    if (
                                      state.formInfo.separateTicketRecipients.reduce(
                                        (acc, curr) =>
                                          acc + curr.ticketQuantity,
                                        0
                                      ) ===
                                        state.stackedTicketDetails.reduce(
                                          (acc, curr) =>
                                            acc + curr.ticketQuantity,
                                          0
                                        ) ||
                                      state.formInfo.separateTicketRecipients
                                        .length === state.ticketDetails.length
                                    )
                                      return
                                    dispatch({
                                      type: 'UPDATE_FORM', // add a new recipient
                                      payload: {
                                        path: 'separateTicketRecipients',
                                        value:
                                          state.formInfo.separateTicketRecipients.concat(
                                            // fill with default values
                                            [
                                              {
                                                email:
                                                  state.formInfo
                                                    .separateTicketRecipients
                                                    .length === 0
                                                    ? state.formInfo
                                                        .contactInformation
                                                        .email
                                                    : '',
                                                firstName:
                                                  state.formInfo
                                                    .separateTicketRecipients
                                                    .length === 0
                                                    ? state.formInfo
                                                        .contactInformation
                                                        .firstName
                                                    : '',
                                                lastName:
                                                  state.formInfo
                                                    .separateTicketRecipients
                                                    .length === 0
                                                    ? state.formInfo
                                                        .contactInformation
                                                        .lastName
                                                    : '',
                                                ticketId:
                                                  state.stackedTicketDetails[0]
                                                    .ticketId,
                                                ticketQuantity:
                                                  state.separateTicketDeliveryMode ===
                                                  'allowed'
                                                    ? 0
                                                    : 1
                                              }
                                            ]
                                          )
                                      }
                                    })
                                  }}
                                >
                                  Add new recipient
                                </Button>
                              </div>
                            )}
                        </>
                      )}
                      {/* user registration section */}
                    </form>
                  </div>
                  <div className='border-top d-flex d-lg-none d-xl-none'></div>
                  <div className='border-top d-none w-100 p-4 text-right  d-xl-flex d-lg-flex align-items-center justify-content-between'>
                    <Link
                      to={{
                        pathname: `/cart/${props.match.params.id}`
                      }}
                      className='btn btn-back btn-outline-brand border-brand  font-size-14 font-weight-bold pl-4 pr-4 pt-2 pb-2 text-brand'
                    >
                      <i className='fas fa-arrow-left mr-2'></i>
                      Return to Cart
                    </Link>
                  </div>
                </div>

                {/* Order Summary section */}
                <div className='col-lg-4  d-xl-block d-lg-block'>
                  <img
                    src={props.location.state.eventImage}
                    alt='Event'
                    title=''
                    className='w-100 d-hide-img'
                  />
                  <div className='p-4'>
                    <h5 className='font-weight-bold pb-3'>Order Summary</h5>
                    {
                      <>
                        {state.stackedTicketDetails.map((item, index) => {
                          return (
                            <>
                              {item.ticketQuantity && (
                                <>
                                  <div className='row no-gutters'>
                                    <div className='col-8'>
                                      <p className='text-capitalize'>
                                        {`${item?.ticketQuantity} x ${item?.ticketName}`}
                                      </p>
                                    </div>
                                    <div className='col-4'>
                                      <p className='float-right'>
                                        {`$${Number(
                                          item?.ticketPriceShow || 0
                                        ).toFixed(2)}`}
                                      </p>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )
                        })}
                      </>
                    }

                    <div className='row no-gutters border-bottom pt-4'>
                      <div className='col-8'>
                        <p>Subtotal</p>
                      </div>
                      <div className='col-4'>
                        <p className='float-right'>
                          ${Number(state?.formInfo.subtotal || 0).toFixed(2)}
                        </p>
                      </div>
                      <div className='col-8'>
                        <p>Fees</p>
                      </div>
                      <div className='col-4'>
                        <p className='float-right'>
                          ${Number(state.formInfo.fees).toFixed(2)}
                        </p>
                      </div>
                      {state.formInfo.coupon.discount !== 0 && (
                        <>
                          <div className='col-8'>
                            <p>Discount</p>
                          </div>
                          <div className='col-4'>
                            <p className='float-right color-orng'>
                              -$
                              {Number(
                                state.formInfo.coupon.discountType === 1
                                 ? state.formInfo.coupon.discount * 0.01 * state.ticketDetails.filter(e => state.formInfo.coupon.selectedTickets.includes(e.ticketId)).reduce((acc, curr) => Number(curr.ticketPrice) > 0 ? acc + curr.ticketPrice : acc, 0)
                : state.formInfo.coupon.discount * state.ticketDetails.filter(e => state.formInfo.coupon.selectedTickets.includes(e.ticketId)).reduce((acc, curr) => Number(curr.ticketPrice) > 0 ? acc + 1 : acc, 0)
                              ).toFixed(2)}
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                    {localStorage.getItem('eventID')=='66e9d2c940a762a1f54a667b'?(
                      <div className='row no-gutters pt-4 mb-5'>
                        <div className='col-8'>
                          <p className='font-weight-bold'>LET</p>
                        </div>
                        <div className='col-4'>
                          <p className='float-right font-weight-bold'>
                            ${Number(Number(state.formInfo.grandTotal || 0)*0.09).toFixed(2)}
                          </p>
                        </div>
                      </div>
                    ):''}
                    {localStorage.getItem('eventID')=='66e9d2c940a762a1f54a667b'?(
                      <div className='row no-gutters pt-4 mb-5'>
                        <div className='col-8'>
                          <p className='font-weight-bold'>Total</p>
                        </div>
                        <div className='col-4'>
                          <p className='float-right font-weight-bold'>
                            ${Number((Number(state.formInfo.grandTotal || 0)+Number(state.formInfo.grandTotal || 0)*0.09)).toFixed(2)}
                          </p>
                        </div>
                      </div>
                    ):(
                      <div className='row no-gutters pt-4 mb-5'>
                        <div className='col-8'>
                          <p className='font-weight-bold'>Total</p>
                        </div>
                        <div className='col-4'>
                          <p className='float-right font-weight-bold'>
                            ${Number(state.formInfo.grandTotal || 0).toFixed(2)}
                          </p>
                        </div>
                      </div>
                    )}

                    {Number(state.subtotal) !== 0 && (
                      <div className='row promoCode mt-5'>
                        <div className='col-lg-12  mt-5 code-apply'>
                          <label for='promo'>Have a promo code?</label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Enter Promo Code'
                            name='couponName'
                            value={state.formInfo.coupon.name}
                            onChange={e =>
                              dispatch({
                                type: 'UPDATE_FORM',
                                payload: {
                                  path: 'coupon.name',
                                  value: e.target.value
                                }
                              })
                            }
                            maxLength='50'
                          />
                          {
                            <button className='btnApply' onClick={handleCoupon}>
                              {state.formInfo.coupon.couponId !== ''
                                ? `Remove`
                                : `Apply`}
                            </button>
                          }
                        </div>
                      </div>
                    )}
                    {/* */}
                    {state.errorMessage.couponErrorMessage && (
                      <div className='error ml-0'>
                        {state.errorMessage.couponErrorMessage}
                      </div>
                    )}
                    <div className='string-check string-check-bordered-danger mb-3 mt-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='agree'
                        id='formCheckInput26'
                        value={state.agreeToTerms}
                        checked={state.agreeToTerms}
                        onChange={e =>
                          dispatch({
                            type: 'TOGGLE_AGREE_TO_TERMS',
                            payload: e.target.checked
                          })
                        }
                      />
                      <label
                        className='string-check-label'
                        for='formCheckInput25'
                      >
                        <span className='ml-2'>
                          I agree to the{' '}
                          <a
                            className='link'
                            href='/terms'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            Terms
                          </a>{' '}
                          and{' '}
                          <a className='link' href='/privacy' target='_blank'>
                            Privacy
                          </a>
                        </span>
                      </label>
                    </div>

                    {state.agreeErrorMsg && (
                      <div className='error ml-0 mb-4'>
                        {state.agreeErrorMsg}
                      </div>
                    )}
                    {state.formInfo.userId === '' && (
                      <>
                        <span className='string-check string-check-bordered-danger mt-3'>
                          <input
                            type='checkbox'
                            value={createAccount}
                            className='form-check-input'
                            onChange={() => setCreateAccount(!createAccount)}
                            id='createAccount'
                          />

                          <label
                            className='string-check-label'
                            for='createAccount'
                          >
                            <span className='ml-2'>Create an account?</span>
                          </label>
                        </span>
                        {createAccount && (
                          <div className='row mb-2 mt-1'>
                            <div className='col-md-12'>
                              <div className='form-group createAccountGroup'>
                                <FormInput
                                  value={state.formInfo.password}
                                  name='password'
                                  dispatch={dispatch}
                                  label='Account Password'
                                  required
                                  message='Please enter a valid password'
                                  key='password'
                                />
                                <FormInput
                                  value={state.formInfo.confirmPassword}
                                  name='confirmPassword'
                                  dispatch={dispatch}
                                  label='Confirm Password'
                                  required
                                  message='Please enter a valid password'
                                  key='confirmPassword'
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <hr />
                      </>
                    )}

                    <div className='text-center mb-3'>
                      <button
                        type='submit'
                        className='btn btn-primary btn-block'
                        onClick={handleCheckout}
                        disabled={state.disabledBtn}
                      >
                        Checkout
                      </button>
                      {state.checkoutError && (
                        <div className='error ml-0 mb-4'>
                          {state.errorMessage.checkoutErrorMessage || 'One or more checkout fields are invalid. Please check your information and try again.'} 
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <EventFooter />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// this component handles all form input except for the PlacesAutocomplete component
const FormInput = props => {
  const [valid, setValid] = useState(true)

  let pattern
  // this switch statement sets the regex pattern for each input for form validation
  switch (props.name) {
    case 'contactInformation.email':
    case 'confirmEmail':
      pattern =
        '^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$' // eslint-disable-line
      // ^^ the forward slash needs to be escaped, but if it's not, the formatter removed it and you'll need to add it back
      break
    case 'billingInformation.zip':
      pattern = '^[0-9]{5}(?:-[0-9]{4})?$'
      break
    default:
      pattern = '.*'
  }

  return (
    <>
      {/* Label */}
      {props.name !== 'isReportRequired' && (
        <label className='text-capitalize' htmlFor={props.name}>
          {props.label}
          {props.required && <span className='text-danger'>*</span>}
        </label>
      )}
      {/* Additional wrapper for the actual form component -- this allows us to simulate a switch in JSX, so we can alter the input type based on the form field */}
      <InputWrapper {...props} setValid={setValid} pattern={pattern} />
      {
        <div style={{ display: 'none' }} className='error ml-0'>
          {props.message}
        </div>
      }
    </>
  )
}

const InputWrapper = props => {
  switch (props.name) {
    case 'contactInformation.phone':
      return (
        <ReactPhoneInput
          className='mb-4'
          country={'us'}
          value={props.value}
          onChange={e =>
            props.dispatch({
              type: 'UPDATE_FORM',
              payload: { path: props.name, value: e }
            })
          }
        />
      )
    case 'isReportRequired': // for the covid vaccination status
      return (
        <>
          <input
            type='radio'
            name={props.name}
            id={props.value}
            value={props.value}
            onChange={e =>
              props.dispatch({
                type: 'UPDATE_FORM',
                payload: { path: props.name, value: e.target.value }
              })
            }
            required
          />
          <label className='mx-2 mb-4'>{props.label}</label>
        </>
      )
    case 'contactInformation.gender':
      return (
        <>
          <Select
            placeholder={props.label}
            bordered={false}
            className='cm-select-box-2'
            onChange={value => {
              props.dispatch({
                type: 'UPDATE_FORM',
                payload: { path: props.name, value: value }
              })
            }}
          >
            <Option value='Male'>Male</Option>
            <Option value='Female'>Female</Option>
            <Option value='Non-Binary'>Non-Binary</Option>
            <Option value='Other'>Other</Option>
          </Select>
        </>
      )
    case 'vaccinationCertificate':
      return (
        <input
          type='file'
          className='form-control'
          name={props.name}
          onChange={e =>
            props.dispatch({
              type: 'UPDATE_FORM',
              payload: { path: props.name, value: e.target.files[0] }
            })
          }
          required
          accept='.pdf, .jpeg, .png'
        />
      )
    default:
      return (
        <InputMask
          mask={props.mask}
          maskChar={props.mask ? ' ' : null}
          type='text'
          className='form-control'
          name={props.name}
          value={props.value}
          onChange={e =>
            props.dispatch({
              type: 'UPDATE_FORM',
              payload: { path: props.name, value: e.target.value }
            })
          }
          onInvalid={() => {
            props.setValid({ valid: false })
          }}
          required={props.required}
          pattern={props.pattern}
          // maxLength='50'
        />
      )
  }
}

export default EventCheckout
